$(function(){
    $('.pub-gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.images-row-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows:false,
        dots: false,
        infinite: true,
        autoplay: true,
        cssEase: 'linear',
        speed: 900,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-nav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.pub-gallery',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true
    });


    var loopproduct_height = $(".loopproduct-image-wrapper");
    if(loopproduct_height.length){
        loopproduct_height.sameHeight();
    }
    var product__title = $(".woocommerce-loop-product__title");
    if(product__title.length){
        product__title.sameHeight();
    }
    var LoopProduct_link = $(".woocommerce-LoopProduct-link");
    if(LoopProduct_link.length){
        LoopProduct_link.sameHeight();
    }


    $('.menu-btn').on("click",function() {
        $('.main-nav').addClass('active');
        $('#menu-main-menu').addClass('active');
    });

    $('.close-menu').on("click",function() {
        $('.main-nav').removeClass('active');
        $('#menu-main-menu').removeClass('active');
    });
    $('.my-account-menu').on("click",function() {
        $('.close-menu').trigger("click");
        $('.top_navbar_menu, .top_navbar_menu .main-nav').addClass('active');
        $('#menu-main-menu').addClass('active');
    });

    $('#my-account-menu-btn').on("click",function() {
        $(this).toggleClass('is-active');
        $('nav.woocommerce-MyAccount-navigation').slideToggle();
    });

    $('.close-all-menu-children').on('click', function(){
        $('.menu-item-has-children').each(function(){
            $(this).removeClass('active');
        });

        $('.menu-item-children').each(function(){
            $(this).removeClass('active');
        });

        $('.page-head').removeClass('menu_active');
    });

    var currentMousePos = { x: -1, y: -1 };
    $(window).mousemove(function(event) {
        currentMousePos.x = event.pageX;
        currentMousePos.y = event.pageY - $(window).scrollTop();

        let window_width = $(window).outerWidth();
        if(window_width > 1366){
            let side_margins = (window_width - 1366) / 2;
            let left_edge_margin = side_margins + 40;
            let right_edge_margin = side_margins + 1366 - 80;

            if(currentMousePos.x < left_edge_margin || currentMousePos.x > right_edge_margin){
                $('.close-all-menu-children').click();
            }
        }

        // console.log(currentMousePos);
        if(currentMousePos.y > 675){
            if($('.page-head').hasClass('menu_active')){
                $('.close-all-menu-children').click();
            }
        }
    });

    $('.menu-item-level-1').on("mouseover",function() {
        var _this = this;
        var _id = $(_this).data('id');

        $('.menu-item-level-1').each(function(){
            $(this).removeClass('active');
        });
        $('.menu-item-has-children').each(function(){
            $(this).removeClass('active');
        });
        $(_this).addClass('active');

        if($(_this).hasClass('menu-item-has-children'))
        {
            if(!$('#menu-item-children-' + _id).hasClass('active')){
                //-- desktop parent menu trigger
                $('#menu-item-children-' + _id).addClass('active');

                if(!$(_this).hasClass('menu-child-item-parent')){
                    $('.page-head').addClass('menu_active');
                }
            }

            $('.menu-item-has-children').each(function(){
                let _id2 = $(this).data('id');
                if(_id != _id2){
                    $('#menu-item-children-' + _id2).removeClass('active');
                }
            });
        }
        else
        {
            $('.menu-item-has-children').each(function(){
                $('.menu-item-children').removeClass('active');
            });

            //-- desktop parent menu trigger
            $('#menu-item-children-' + _id).addClass('active');

            $('.page-head').removeClass('menu_active');
        }

    });

    $('.menu-item-level-1').on("mouseout",function() {
        var _this = this;

        if(!$(_this).hasClass('menu-item-has-children')){
            $(_this).removeClass('active');
        }
    });

    $('.menu-item-level-2').on("mouseover",function() {
        var _this = this;
        var _id = $(_this).data('id');

        $('.menu-item-level-2').each(function(){
            $(this).removeClass('active');
            $('#menu-item-children-' + $(this).data('id')).removeClass('active');
        });

        $(_this).addClass('active');
        $('#menu-item-children-' + _id).addClass('active');

    });

    $('.menu-item-has-children').on("click",function() {
        $(this).toggleClass('active');
        var _id = $(this).data('id');

        if($(this).hasClass('mobile-menu-item-parent-level1'))
        {
            //-- mobile parent menu trigger
            $('#mobile-menu-item-level2-' + _id).toggleClass('active');
        }
        else if($(this).hasClass('mobile-menu-item-parent-level2'))
        {
            //-- mobile parent menu trigger
            $('#mobile-menu-item-level3-' + _id).toggleClass('active');
        }
        else
        {
            //-- desktop parent menu trigger
            $('#menu-item-children-' + _id).toggleClass('active');

            if(!$(this).hasClass('menu-child-item-parent')){
                $('.page-head').toggleClass('menu_active');
            }
        }
    });

    $('.mobile-menu-btn').on("click",function() {
        if($(this).hasClass('disabled')){
            return false;
        }

        if($('.mobile-menu').hasClass('active'))
        {
            //-- close all level2 and level3 menu
            $('.mobile-menu-item-level2').each(function(){
                $(this).removeClass('active');
            });

            $('.mobile-menu-item-level3').each(function(){
                $(this).removeClass('active');
            });

            $('body').removeClass('menu-active');
        }
        else{
            $('body').addClass('menu-active');
        }

        $(this).toggleClass('active');
        $('.mobile-menu').toggleClass('active');
        $('.mobile-search-btn').toggleClass('disabled');
    });

    $('.mobile-menu-close-level1').on("click",function() {

        if($('.mobile-menu').hasClass('active')){
            $('body').removeClass('menu-active');
        }
        else{
            $('body').addClass('menu-active');
        }

        $('.mobile-menu-btn').toggleClass('active');
        $('.mobile-menu').toggleClass('active');
        $('.mobile-search-btn').toggleClass('disabled');
    });

    $('.mobile-menu-close-level2').on("click",function() {
        var _id = $(this).data('id');
        $('#mobile-menu-item-' + _id).click();
    });

    $('.mobile-menu-close-level3').on("click",function() {
        var _id = $(this).data('id');
        $('#mobile-menu-item-level2-' + _id).click();
    });

    $('.filter-head').on("click",function() {
        $(this).toggleClass('active');
        $('.search-bar').toggleClass('active');
        $('.category-filters').toggleClass('active');
    });

    $(document).on("click",".table-iframe",function(e) {
        e.preventDefault();
        var $live_res_guid          = $(this).data('live-res-guid');
        var $designmynight_site_id  = $(this).data('designmynight_site_id');
        if($designmynight_site_id){
            var $live_res_iframe = $('#designmynight');
            $live_res_iframe.html('<script src="//widgets.designmynight.com/bookings-partner.min.js" dmn-booking-form="true" search-venues="'+$designmynight_site_id+'," dmn-booking-form venue="'+$designmynight_site_id+'," google-tag-manager-code="GTM-K7S6PLS"></script>');
            $('#table-book-designmynight').addClass('active');
        }else if ($live_res_guid) {
            var $live_res_iframe = $('#table-book-new .js-reframe > iframe');
            $live_res_iframe.attr('src', $live_res_iframe.attr('src') + '&siteId=' + $live_res_guid);

            $('#table-book-new').addClass('active');
        }else{
            $('#table-book-new').addClass('active');
        }

    });

    $('.close-iframe').on("click",function() {
        $('.table-book-wrap').removeClass('active');
    });

    $('.dropdown-title').on("click",function() {
        $(this).siblings('.dropdown-options').toggleClass('active');
    });

    var scrollTop = 0;
    $(window).scroll(function() {
        scrollTop = $(window).scrollTop();
        // $('.counter').html(scrollTop);

        if (scrollTop >= 80) {
            $('.page-head').addClass('scrolled-nav');
        } else if (scrollTop < 80) {
            $('.page-head').removeClass('scrolled-nav');
        }
    });
    $('.hero-book').on("click",function() {
        $('.hero-popup').addClass('active');
    });
    $('.form-close').on("click",function() {
        $('.hero-popup').removeClass('active');
    });

    // ------ Search Bar Function
    $('.search-bar input[type=text]').on('keydown', function(e) {
        if (e.which == 13) {
            $(this).parent().find('input[type=submit]').trigger('click');
            $('.properties-list.landing').addClass('inactive');
            $('.properties-list.search-results ul').empty();
            $('.properties-list.search-results').addClass('active');
            jQuery.ajax({
                url: '/wordpress/wp-admin/admin-ajax.php',
                type: 'GET',
                data: {
                    action: 'data_fetch',
                    keyword: $('#keyword').val()
                },
                success: function(data) {
                    $('.properties-list.search-results ul').append(data);
                    //initTableIframe();
                }
            });

        }
    });

    if (typeof window.location.search.substr(1) != "undefined") {
        if (window.location.search.substr(1) === "book-a-room") {
            setTimeout(function() { $('.cat-box[name="6"]').trigger("click"); }, 1000);
        }
    }
    // ------ Offers Category Filter
    $('.cat-box-offers').on("click",function() {

        var choices = {};
        $('.offers-list ul').empty();

        $('input[type=checkbox]:checked').each(function() {
            if (!choices.hasOwnProperty(this.name))
                choices[this.name] = [this.value];
            else
                choices[this.name].push(this.value);
        });

        // console.log(choices);
        jQuery.ajax({
            url: '/wordpress/wp-admin/admin-ajax.php',
            type: 'GET',
            data: {
                'action': 'call_offers', // Name of the PHP function
                'choices': choices,
            },
            success: function(result) {
                $('.offers-list ul').append(result);
                // For testing - Result (Can be deleted later)
                // console.log(Result);
                // console.log(choices);
            },
            error: function(err) {
                // For testing - Error (Can be removed later)
                console.log(err);
                console.log(choices);
            }
        });

    });

    // ------ Pubs & Hotels Category Filter
    $('.cat-box').on("click",function() {

        var choices = {};
        $('.properties-list ul.managed-list').empty();

        $('input[type=checkbox]:checked').each(function() {
            if (!choices.hasOwnProperty(this.name))
                choices[this.name] = [this.value];
            else
                choices[this.name].push(this.value);
        });

        // console.log(choices);
        jQuery.ajax({
            url: '/wordpress/wp-admin/admin-ajax.php',
            type: 'GET',
            data: {
                'action': 'call_post', // Name of the PHP function
                'choices': choices,
            },
            success: function(result) {
                $('.properties-list ul.managed-list').append(result);
                // For testing - Result (Can be deleted later)
                // console.log(Result);
                // console.log(choices);
            },
            error: function(err) {
                // For testing - Error (Can be removed later)
                console.log(err);
                console.log(choices);
            }
        });

    });

    $('.close-iframe').on("click",function() {
        $('.table-book-wrap').removeClass('active');
    });

    function initTableIframe() {
        $('.table-iframe').click(function(e) {
            e.preventDefault();

            var $live_res_guid = $(this).data('live-res-guid');
            var $designmynight_site_id = $(this).data('designmynight_site_id');
            if($designmynight_site_id){
                var $live_res_iframe = $('#designmynight');
                $live_res_iframe.html('<script src="//widgets.designmynight.com/bookings-partner.min.js" search-venues="'+$designmynight_site_id+'," dmn-booking-form venue="'+$designmynight_site_id+'," google-tag-manager-code="GTM-K7S6PLS"></script>');
                $('#table-book-designmynight').addClass('active');
            }else if ($live_res_guid) {

                var $live_res_iframe = $('#table-book-new .js-reframe > iframe');
                $live_res_iframe.attr('src', $live_res_iframe.attr('src') + '&siteId=' + $live_res_guid);
                $('#table-book-new').addClass('active');
            }else{
                $('#table-book-new').addClass('active');
            }
        });
    }

    //initTableIframe();

    $('#billing_postcode, #shipping_postcode').on("blur change", function() {
        $(document.body).trigger('update_checkout');
        console.log("Update checkout");
    });

    $('button[name="add-to-cart"]').on('click', function(){
        fbq('track', 'AddToCart');
    });

    $('a.checkout-button').on('click', function(){
        fbq('track', 'InitiateCheckout');
    });

    $('#newsletter_form_like_klaviyo').on('submit', function(e){
        let form_data = jQuery( this ).serializeArray();

        form_data.push( { "name" : "nonce", "value" : newsletter_ajax_nonce } );
        form_data.push( { "name" : "action", "value" : "subscribe_form" } );
        let data = {};
        for(let i = 0; i < form_data.length; i++){
            data[form_data[i].name] = form_data[i].value;
        }

        // Here is the ajax petition.
        jQuery.ajax({
            url : ajax_url, // Here goes our WordPress AJAX endpoint.
            type : 'post',
            data : data,
            success : function( response ) {
                // You can craft something here to handle the message return
                $('#newsletter_form_like_klaviyo').replaceWith("<h4>Thanks for subscribing!</h4><p>Check your email for a confirmation message.</p>");
            },
            fail : function( err ) {
                // You can craft something here to handle an error if something goes wrong when doing the AJAX request.
                console.log( "There was an error: " + err );
            }
        });

        // This return prevents the submit event to refresh the page.
        return false;
    });
    let outW = $(window).outerWidth();
    if(outW > 1025){
        checkCards($('ul.managed-list').children(), 4);
        checkCards($('ul.tenanted-list').children(), 4);
    }else{
        if(outW > 749){
            checkCards($('ul.managed-list').children(), 2);
            checkCards($('ul.tenanted-list').children(), 2);
        }
    }

    checkSubmenuWidth();

    $('nav.main-nav ul li.menu-item-has-children').on('click', (e) => {
        let el = e.target;
        let nav = $(el).parent();
        if($(nav).hasClass('active')){
            e.preventDefault();
        }
    });
    initEventHandlers();

    const gallery = $('.gallery-block > .images');
    // On init
    $(gallery).on('init', function (event, slick) {
        var currentSlide = $(gallery).find('.slick-current');
        var prevSlide = $(currentSlide).prev();
        var prevElem = $(prevSlide).find('li');
        $(prevElem).css('paddingRight', '18px');
        $(prevElem).css('opacity', '0.5');

        var nextSlide = $(currentSlide).next();
        var nextElem = $(nextSlide).find('li');
        $(nextElem).css('paddingLeft', '18px');
        $(nextElem).css('opacity', '0.5');

        $(prevElem).click(function () {
            $('.sliderPrev').trigger('click');
        });
        $(nextElem).click(function () {
            $('.sliderNext').trigger('click');
        });

        $('ul.slick-dots').children().each(function (k, v) {
            if (k >= 5) {
                $(this).remove();
            }
        })
    });

    $(gallery).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        prevArrow: ".sliderPrev",
        nextArrow: ".sliderNext",
        centerMode: true,
        centerPadding: '14vw',
        dots: true,
        appendDots: '.block.gallery-block',
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    centerPadding: '5vw'
                }
            },
            {
                breakpoint: 750,
                settings: {
                    centerPadding: '0vw'
                }
            },
        ],
        customPaging: function (slider, i) {
            return $('<button type="button" />');
        }
    });
    $(gallery).on('breakpoint', function (event, slick, breakpoint) {
        $('ul.slick-dots').children().each(function (k, v) {
            if (k >= 5) {
                $(this).remove();
            }
        });

    });

    // On before slide change
    $(gallery).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var currentSlide = $(gallery).find('.slick-current');
        var prevSlide = $(currentSlide).prev();
        var prevElem = $(prevSlide).find('li');
        $(prevElem).css('paddingRight', '0px');
        $(prevElem).css('opacity', '1');

        var nextSlide = $(currentSlide).next();
        var nextElem = $(nextSlide).find('li');
        $(nextElem).css('paddingLeft', '0px');
        $(nextElem).css('opacity', '1');

        $(prevElem).unbind('click');
        $(nextElem).unbind('click');
    });

    // On after slide change
    $(gallery).on('afterChange', function (event, slick, currentSlide) {

        var currentSlide = $(gallery).find('.slick-current');
        var prevSlide = $(currentSlide).prev();
        var prevElem = $(prevSlide).find('li');
        $(prevElem).css('paddingRight', '18px');
        $(prevElem).css('opacity', '0.5');

        var nextSlide = $(currentSlide).next();
        var nextElem = $(nextSlide).find('li');
        $(nextElem).css('paddingLeft', '18px');
        $(nextElem).css('opacity', '0.5');

        $(prevElem).click(function () {
            $('.sliderPrev').trigger('click');
        });
        $(nextElem).click(function () {
            $('.sliderNext').trigger('click');
        });
    });
});
function checkSubmenuWidth(){
    $('nav.main-nav .main-nav > .menu-item-has-children').each((k,v) => {
        $w = $(v).outerWidth();
        $(v).children('.dropdown').css('minWidth', $w);
    });
}
$(window).resize(function() {
    let outW = $(window).outerWidth();
    if(outW > 1025){
        checkCards($('ul.managed-list').children(), 4);
        checkCards($('ul.tenanted-list').children(), 4);
    }else{
        if(outW > 749){
            checkCards($('ul.managed-list').children(), 2);
            checkCards($('ul.tenanted-list').children(), 2);
        }else{
            resetCards($('ul.managed-list').children());
            resetCards($('ul.tenanted-list').children());
        }
    }
});

function initEventHandlers(){
    $('.header-search-field-trigger').on('click', function(){
        $('#header-search-field-container').addClass('active');

        $('.header-btn').each(function(){
            $(this).addClass('search-active');
        });
    });

    $('.header-close-btn').on('click', function(){
        $('#header-search-field-container').removeClass('active');

        $('.header-btn').each(function(){
            $(this).removeClass('search-active');
        });
    });

    $('.global-floating-cta-form .form-close-btn, .global-floating-cta-form .form-open-btn').on('click', function(){
        $('.global-floating-cta-form').toggleClass('active');
    });

    $('.flexible-hero-block > .hero-slider').not('.slick-initialized').slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        cssEase: 'linear',
        speed: 900,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
        adaptiveHeight: false,
    });

    $('.custom-anchor-tag').off('click');
    $('.custom-anchor-tag').on('click', function() {
        var _href = $(this).data('href');
        if(_href != '' && _href != undefined){
            location.href = _href;
        }
        return false;
    });

    // $('.post-type-search-btn').off('click');
    $('.post-type-search-btn').on('click', function() {
        if($(this).hasClass('disabled')){
            return false;
        }

        var _href = $(this).data('href');
        var _search_field_id = $(this).data('search-field');

        if($('#search-post-type-category-slug').length > 0 && $('#search-post-type-category-slug').val() != ""){
            _href += $('#search-post-type-category-slug').val() + '/';
        }

        if(_href != '' && _href != undefined){
            $(this).addClass("disabled");
            $(this).html("Loading...");

            location.href = _href + '?search=' + $('#' + _search_field_id).val();
        }
        return false;
    });

    $('.post-type-search-btn-cinema').off('click');
    $('.post-type-search-btn-cinema').on('click', function() {
        if($(this).hasClass('disabled')){
            return false;
        }

        let name = $('#banner-search-field-second_level_property_template').val();
        let location = $('#banner-search-field-second_level_property_template-select').val();
        let date = $('.find-a-pub-search-banner-dates-select').val();


        let found_result = false;
        let scroll_down = false;
        if(name.length > 0){
            $('.content-heading').each((k,v) => {
                if($(v).html().toLowerCase() === name.toLowerCase()){
                    $(v).closest('.pub-hotel').show();
                    found_result = true;
                    scroll_down = true;
                }else{
                    $(v).closest('.pub-hotel').hide();
                }
            });
        }

        if(location.length > 0){
            $('.button_filter_location').each((k,v) => {
                if($(v).data('id') === Number(location)){
                    found_result = true;
                    $(v).trigger('click');
                }
            });
        }

        if(date.length > 0){
            $('.pub-hotel').each((k,v) => {
                if($(v).hasClass(date)){
                    $(v).show();
                    found_result = true;
                    scroll_down = true;
                }else{
                    $(v).hide();
                }
            });
        }

        if(scroll_down){
            $([document.documentElement, document.body]).animate({
                scrollTop: ($("#place_name").offset().top - 150)
            }, 500);
        }
        return false;

    });

    $('.banner-search-field, .banner-search-field-text').off('keyup');
    $('.banner-search-field, .banner-search-field-text').on('keyup', function(e) {
        var _id = $(this).attr('id');
        if(e.which == 13){
            $('#' + _id + '-button').click();
        }

        return false;
    });

    $('.header-search-btn-trigger').off('click');
    $('.header-search-btn-trigger').on('click', function(e) {
        location.href = '/our-pubs/' + '?search=' + $('#header-search-field-autocomplete').val();
        return false;
    });

    $('#header-search-field-autocomplete').off('keyup');
    $('#header-search-field-autocomplete').on('keyup', function(e) {
        if(e.which == 13){
            location.href = '/our-pubs/' + '?search=' + $('#header-search-field-autocomplete').val();
        }

        return false;
    });

    $('.find-a-pub-search-banner-location-select').off('change');
    $('.find-a-pub-search-banner-location-select').on('change', function() {
        if($(this).val() != ""){
            var _btn_id = $(this).attr('id').replace('-select', '-button');
            var _href = $(this).val();

            $('#' + _btn_id).addClass("disabled");
            $('#' + _btn_id).html("Loading...");

            location.href = _href;
        }
        return false;
    });

    $('.find-a-pub-search-banner-location-select-cinema').off('change');
    $('.find-a-pub-search-banner-location-select-cinema').on('change', function() {
        if($(this).val() != ""){
            var _btn_id = $(this).attr('id').replace('-select', '-button');
            $('#' + _btn_id).click();
        }
        return false;
    });

    $('.mobile-search-btn, .mobile-header-close-btn').off('click');
    $('.mobile-search-btn, .mobile-header-close-btn').on('click', function() {
        if($(this).hasClass('disabled')){
            return false;
        }
        $('.mobile-menu-search').toggleClass('active');
        $('.mobile-menu-btn').toggleClass('disabled');
        $('.mobile-search-btn').toggleClass('disabled');
    });

    $('.search-category-button-showmore').off('click');
    $('.search-category-button-showmore').on('click', function() {
        var _id = $(this).data('id');

        if($('.search-category-button-group-container-' + _id).is(':visible')){
            $(this).html('show more...');
            $('.search-category-button-group-container-' + _id).slideUp();
        }
        else{
            $(this).html('show less...');
            $('.search-category-button-group-container-' + _id).slideDown();
        }

    });

    $('.global-contact-us-button').off('click');
    $('.global-contact-us-button').on('click', function() {
        var _href = $(this).data('href');
        window.open(_href, '_blank');
        // window.open(_href, '_blank', 'height=400,width=600,ocation=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,bottom=10,right=10');
        // $('#global-contact-us-popup > iframe').attr('src', _href);
        // $('#global-contact-us-popup').addClass('active');
    });

    $('#global-contact-us-popup .popup-close-btn').off('click');
    $('#global-contact-us-popup .popup-close-btn').on('click', function() {
        $('#global-contact-us-popup').removeClass('active');
    });

    if($('.smooth-scroll-here').length > 0)
    {
        var _scroll_to = $('.smooth-scroll-here').attr('id');
        $('html, body').animate({
            scrollTop: $('#' + _scroll_to).offset().top - 120
        }, 800);
    }

    setTimeout(function(){
        let outW = $(window).outerWidth();
        if(outW > 746){
            blocksSameHeight();
        }
    }, 1000);
}

function resetCards(elements){
    $(elements).children('.property-details').children('p').css('height', 'auto');
    $(elements).children('.property-details').children('p').css('height', 'auto');
    $(elements).children('.property-details').children('ul').css('height', 'auto').css('marginBottom', '15px');
    $(elements).children('.property-details').children('ul').css('height', 'auto').css('marginBottom', '15px');
}

function checkCards(elements, rowElements){
    resetCards(elements);
    let maxP = 0;
    let maxUL = 0;
    $(elements).each((k,v)=>{
        let hP = $(v).children('.property-details').children('p').eq(0).outerHeight();
        if (hP > maxP){
            maxP = hP;
        }
        let hUL = $(v).children('.property-details').children('ul').eq(0).outerHeight();
        if (hUL > maxUL){
            maxUL = hUL;
        }
        if(k > 0 && (k + 1) % rowElements === 0){
            for(let i = 0; i < rowElements; i++){
                let idx = k - i;
                let p = $(elements).eq(idx).children('.property-details').children('p').eq(0);
                $(p).css('height', maxP);
                let ul = $(elements).eq(idx).children('.property-details').children('ul').eq(0);
                let hULcurrent = $(ul).outerHeight();
                if(hULcurrent === maxUL) {
                    $(ul).css('height', maxUL);
                }else{
                    let diff = maxUL - hULcurrent + 15;
                    $(ul).css('marginBottom', diff);
                }
            }
            maxP = 0;
            maxUL = 0;
        }
    })
    let k = $(elements).length - 1;
    for(let i = 0; i < (k + 1) % rowElements; i++){
        let idx = k - i;
        let p = $(elements).eq(idx).children('.property-details').children('p').eq(0);
        $(p).css('height', maxP);
        let ul = $(elements).eq(idx).children('.property-details').children('ul').eq(0);
        let hULcurrent = $(ul).outerHeight();
        if(hULcurrent === maxUL) {
            $(ul).css('height', maxUL);
        }else{
            let diff = maxUL - hULcurrent + 15;
            $(ul).css('marginBottom', diff);
        }
    }
}

function setPlayer(youtube_code){
    let bkg = document.createElement('div');
    bkg.id = 'player_bkg';
    bkg.style.position = 'fixed';
    bkg.style.top = 0;
    bkg.style.left = 0;
    bkg.style.backgroundColor = 'rgba(0,0,0,0.8)';
    bkg.style.width = '100vw';
    bkg.style.height = '100vh';
    bkg.style.zIndex = '99999999';
    bkg.addEventListener('click', destroyPlayer);
    let player_wrapper = document.createElement('div');
    player_wrapper.id = 'player_wrapper';
    player_wrapper.style.width = '1000px';
    player_wrapper.style.height = '530px';
    player_wrapper.style.position = 'fixed';
    player_wrapper.style.top = 'calc((100vh - 500px) / 2)';
    player_wrapper.style.left = 'calc((100vw - 1000px) / 2)';
    player_wrapper.style.zIndex = '100000000';

    let close = document.createElement('span');
    close.id = 'close_player';
    close.innerHTML = 'x';
    close.addEventListener('click', destroyPlayer);
    close.style.cursor = 'pointer';
    close.style.position = 'absolute';
    close.style.right = 0;
    close.style.color = 'white';
    close.style.fontFamily = 'monospace';
    close.style.fontSize = '30px';

    let player = document.createElement('div');
    player.id = 'main_player';
    player.style.position = 'absolute';
    player.style.top = '30px';

    player_wrapper.append(close);
    player_wrapper.append(player);
    bkg.append(player_wrapper);
    document.body.append(bkg);

    if(typeof YT !== 'undefined'){
        //YT player loaded already
        let p = new YT.Player('main_player', {
            height: '500px',
            width: '1000px',
            videoId: youtube_code
        })
    }else{
        // Load the IFrame Player API code asynchronously.
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/player_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.playme = youtube_code;

        // Replace the 'ytplayer' element with an <iframe> and
        // YouTube player after the API code downloads.
        window.onYouTubePlayerAPIReady = function() {
            new YT.Player('main_player', {
                height: '500px',
                width: '1000px',
                videoId: youtube_code
            })
        }

    }
}

function destroyPlayer() {
    document.getElementById('player_bkg').remove();
}

function blocksSameHeight(){
    /** script to align heading, excerpt content in blocks - start **/
    var _block_names = [];
    _block_names.push('three-column-post-block');
    _block_names.push('post-type-search-block');
    $.each(_block_names, function( index, value ) {
        $('.' + value).each(function(){
            var _height_heading = 40;
            var _height_excerpt = 120;
            var _this = this;
            var cardwrapper = $(_this).find('.same-height-wrapper');
            if(cardwrapper.length){
                cardwrapper.find('.content-wrapper').sameHeight();
            }
        });
    });

    var _block_names = [];
    _block_names.push('managed-list');
    $.each(_block_names, function( index, value ) {
        $('.' + value).each(function(){
            var _height_heading = 40;
            var _height_excerpt = 120;
            var _this = this;
            var cardwrapper = $(_this);
            if(cardwrapper.length){
                cardwrapper.find('.content-heading').sameHeight();
                cardwrapper.find('.content-wrapper').sameHeight();
                cardwrapper.find('.content').sameHeight();
            }
        });
    });

    _block_names = [];
    _block_names.push('dummy-block');
    $.each(_block_names, function( index, value ) {
        $('.' + value).each(function(){
            var _height_heading = 40;
            var _height_excerpt = 120;
            var _this = this;
            var cardwrapper = $(_this).find('.card-wrapper');
            if(cardwrapper.length){
                cardwrapper.find('.subheading').sameHeight();
                cardwrapper.find('.heading').sameHeight();
                cardwrapper.find('.content-wrapper').sameHeight();
                cardwrapper.find('.content').sameHeight();
            }
        });
    });
}

function scrollToId(_id){
    jQuery('html, body').animate({
        scrollTop: jQuery('#' + _id).offset().top - 108
    }, 800, function(){
        // Add hash (#) to URL when done scrolling (default click behavior)
        // window.location.hash = _id;
    });
}

jQuery(window).click(function(evt) {
    if(jQuery('h2#ot-pc-title').length > 0){
        jQuery('#ot-pc-title').replaceWith('<p id="ot-pc-title">Privacy Preference Center</p>');
    }
});